import React from "react";
import { Helmet } from "react-helmet"
import { Container, Row, Col, Button, Accordion, Card } from "react-bootstrap"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { CheckCircle } from 'react-bootstrap-icons'
import App from './App-po-member'
import '../styles/style-free.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'

// const LoginForm = ({ onSubmit }) => {
//     return (

//         <div className="_button-wrapper _full_width">
//             <div className="col-lg-12">
//                 <div className="thumbnail-img-range-order">
//                     <LazyLoadImage
//                         alt={'Mockup HLG'}
//                         src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/new-range.png'}
//                         className={'img img-fluid'}
//                         effect="blur"
//                     />
//                     <p className="text-center">Lihat Lebih Jelas <a href="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/new-range.png" target="_BLANK" rel="noreferrer">Klik Disini</a></p>
//                 </div>
//             </div>
//         </div>

//     );
// };

export default function Book() {
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // const onLoginFormSubmit = (e) => {
    //     e.preventDefault();
    //     handleClose();
    // };
    return (
        <>
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Buku Happy Love Guide Khusus Members</title>
                </Helmet>
            </div>
            {/* this section countdown */}
            <App />
            {/* end section countdown */}
            {/* this is section one */}
            <Container fluid className="mrgn-top-po-member">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md sm>
                            <div className="size-mockup-book-hlg-po">
                                <LazyLoadImage
                                    alt={'Mockup HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/Mockup-dpn-belakan-HLG-hardcover.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={6} md sm className="d-flex justify-content-center">
                            <div className="text-headline-hlg">
                                <p className="p-text-warning">
                                    <b>
                                        {/* Dapatkan Buku Buat Dia Terus Kembali GRATIS Jika */}
                                        Pre Order Buku Happy Love Guide Sekarang!
                                    </b>
                                </p>
                                <h1 className="font-happy padding-text-po">
                                    Happy Love Guide
                                </h1>
                                <p className="font-guide-single-po-member">
                                    Panduan Bahagia Mulai Dari Single
                                    Hingga Bertemu Pria Tepat
                                </p>
                                <p className="font-guide-single-po">
                                    Pesan Dibawah Ini
                                </p>
                                <a href="https://wa.link/lvh4ns" target="_BLANK" rel="noreferrer">
                                    <Button className="button-style-sl-po-green ml-1" size="md" >
                                        Jabodetabek
                                    </Button>
                                </a>
                                <a href="https://wa.link/3j7m8u" target="_BLANK" rel="noreferrer">
                                    <Button className="button-style-sl-po ml-1" size="md">
                                        Non Jabodetabek
                                    </Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg-sectwo mt-5"></Container>
            <Container fluid className="bg-faq-po">
                <Container className="text-fast-need-po">
                    <h1 className="font-happy-po-member text-white padding-text-po">
                        Seberapa cepat Kamu ingin:
                    </h1>
                    <Row>
                        <div className="col-lg-6">
                            <div className="row justify-content-center mt-5">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-po-member" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc-po">
                                        <b>Terhindar dari hubungan "jalanin aja dulu.."</b> tapi gak jelas arah dan tujuannya
                                    </p>
                                </Col>
                            </div>
                            <div className="row justify-content-center">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-po-member" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc-po"> Kuasai teknik <b>"Wanita yang Memilih, Pria yang Mengejar"</b> </p>
                                </Col>
                            </div>
                            <div className="row justify-content-center">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-po-member" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc-po">Punya <b>mindset yang tepat</b> untuk memulai, menjalani dan menciptakan hubungan yang sehat dan bahagia</p>
                                </Col>
                            </div>
                            <div className="row justify-content-center">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-po-member" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc-po">Tau skill, sikap & mindset yang dibutuhkan untuk <b>mendapat pria yang tepat</b></p>
                                </Col>
                            </div>
                            <div className="row justify-content-center">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-po-member" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc-po"><b>Tau hal seksi yang bisa memikat pria namun tidak murahan</b></p>
                                </Col>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row justify-content-center mt-5">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-po-member" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc-po">
                                        Punya <b>skill validasi</b> agar memudahkan memilih diantara beberapa yang mendekat dan tidak berlarut-larut dalam proses yang menguras emosi
                                    </p>
                                </Col>
                            </div>
                            <div className="row justify-content-center">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-po-member" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc-po">Kuasai cara paling efektif bertemu pria yang tepat <b>meskipun kamu gak punya banyak kenalan atau jarang ada waktu bersosialisasi</b></p>
                                </Col>
                            </div>
                            <div className="row justify-content-center">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-po-member" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc-po"><b>Punya panduan yang jelas,</b> langkah demi langkah dari single hingga bertemu pria yang tepat</p>
                                </Col>
                            </div>
                            <div className="row justify-content-center">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-po-member" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc-po"><b>Bebas dari hubungan drama,</b> bucin dan semua hubungan romansa yang gak pake logika</p>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg-po-member-white">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <h1 className="font-happy-po-member text-purple padding-text-po">
                                Miliki Happy Love Guide Sekarang <br /> Dan Dapatkan:
                            </h1>
                        </Col>
                        <Col lg={12}>
                            <div className="wrapper-text-book-hlg-member">
                                <h2 className="text-center">Buku Happy Love Guide :</h2>
                                <p className="text-center">Panduan bahagia mulai dari single hingga bertemu pria tepat. (Senilai Rp 499.000,-)</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="wrapper-book-po-hlg-member">
                        <Col className="col-lg-4 col-xs-12 col-md-4 col-12">
                            <div className="wrapper-thumbnail-po-member">
                                <LazyLoadImage
                                    alt={'Mockup HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/Mockup-cover-HLG-hardcover.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col className="col-lg-8 col-xs-12 col-md-8 col-12">
                            <div className="background-po-member-hlg">
                                <Container>
                                    <div className="row justify-content-center py-po-member-hlg">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member"><b>Dicetak secara FULL COLOR dengan Hard Cover yang membuat buku ini semakin eksklusif dan photogenic untuk kamu foto!</b></p>
                                        </Col>
                                    </div>
                                    <div className="row justify-content-center py-po-member-hlg-member">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member"><b>Panduan bahagia</b> dari fase single hingga bertemu pria tepat.</p>
                                        </Col>
                                    </div>
                                    <div className="row justify-content-center py-po-member-hlg-member">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member">Mindset #1 yang <b>membuat Kamu bahagia</b></p>
                                        </Col>
                                    </div>
                                    <div className="row justify-content-center py-po-member-hlg-member">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member">Contekan apa <b>yang perlu Kamu lakukan agar punya relationship yang sehat & bahagia</b></p>
                                        </Col>
                                    </div>
                                    <div className="row justify-content-center py-po-member-hlg-member">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member">Di.V.A Roadmap, <b>peta lengkap menuju jodoh sejati</b></p>
                                        </Col>
                                    </div>
                                    <div className="row justify-content-center py-po-member-hlg-member">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member">Kuasai teknik "Wanita yang Memilih, Pria yang Mengejar"</p>
                                        </Col>
                                    </div>
                                    {/* <div className="row justify-content-center py-po-member-hlg-member">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member"><b>BONUS Sticker Lucu</b></p>
                                        </Col>
                                    </div> */}
                                    {/* <div className="row justify-content-center py-po-member-hlg-member">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member"><b>FREE Video "Avatar Secret"</b> (Cara Kilat Bikin Kriteria Pria Tepat)</p>
                                        </Col>
                                    </div> */}
                                    {/* <div className="row justify-content-center py-po-member-hlg-member">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member">BONUS Buku Fisik Buat Dia Terus Kembali Pelajari Buat Dia Terus Kembali <a href="https://buatdiakembali.com/" target="_BLANK" className="text-danger" rel="noreferrer">Disini!</a></p>
                                        </Col>
                                    </div> */}
                                    {/* <div className="row justify-content-center py-po-member-hlg-member">
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <CheckCircle className="iconB-po-member" />
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs>
                                            <p className="font-desc-po-member"><b>FREE Audio Curhat</b> langsung dari <b>Jose Aditya (Khusus 200 Orang Pembeli Pertama)</b>
                                            </p>
                                        </Col>
                                    </div> */}
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="__rv-free-hlg-sec-theree">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="__rv-free-text-headline-sec-six-po-member" id="howbuy">
                                <h2>Untuk memiliki buku Happy Love Guide, ikuti langkah berikut ini:</h2>
                            </div>
                            <div className="__rv-free-box-white-rounded">
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Pembelian bisa dilakukan dengan Klik Khusus Jabodetabek Pesan Disini atau Klik Luar Jabodetabek Pesan Disini sesuai wilayah terdekat Kamu </p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Setelah itu Kamu akan langsung ke link dengan CS kami, lalu lakukan pengisian data dengan benar</p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Lakukan pembayaran sebelum batas transfer habis</p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Kirim bukti transfer kepada CS kami</p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Buku Happy Love Guide akan Kami kirim pada tanggal 6 September 2021</p>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg-white padding-bottom-po-member">
                <Container>
                    <Row>
                        <Col className="__rv-free-headline-text-sec-four-po-member">
                            <h2>Seberapa sering kamu iri melihat orang lain/pasangan yang membagikan momen bahagianya di sosial media?</h2>
                        </Col>
                    </Row>
                    <Row className="__rv-free-top-bottom">
                        <Col lg={6}>
                            <div className="__rv-free-wrappe-text-desc-po-member">

                                <p>
                                    Atau..
                                </p>
                                <p>
                                    Mungkin kamu tidak iri malahan ikut happy, namun kamu menjadikan kebahagiaan mereka sebagai tolak ukur kebahagiaanmu juga.
                                </p>
                                <p>
                                    Sampai-sampai, jika belum bisa seperti mereka kamu jadi gak happy, sering membanding-bandingkan dan mudah sedih, karena gak punya definisi kebahagiaan versi kamu sendiri.
                                </p>
                                <p>
                                    Padahal kan, standar kebahagiaan orang lain belum tentu cocok untuk kita…
                                </p>
                                <p>
                                    Saya jadi berpikir.. <b>Seandainya saja semua wanita punya PANDUAN BAHAGIA,</b> mungkin mereka gak perlu lagi terjebak dalam standar kebahagiaan karir orang lain, standar kebahagiaan romansa orang lain.
                                </p>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="__rv-free-img-desc-sec-four">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Free-HLG-revamp/image-rv.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="__rv-free-wrappe-text-desc-po-member mt-5">
                                <p>
                                    Mereka juga tak perlu lagi buang-buang waktu dengan pria yang salah,
                                </p>
                                <p>
                                    Terhindar dari hubungan yang gak jelas tujuannya,
                                </p>
                                <p>
                                    Tau bagaimana menciptakan hubungan yang sehat sekaligus bahagia,
                                </p>
                                <p>
                                    Mengerti langkah demi langkah mencari pria yang tepat tanpa drama dan air mata.
                                </p>
                                <p>
                                    Karenanya Saya ingin kamu miliki panduannya, sekarang juga!
                                </p>
                                <p>
                                    Terutama bagi kamu wanita single yang ingin bertemu lelaki yang tepat, setia, dan tipe kamu banget
                                </p>
                                <p>
                                    Bagi kamu yang ingin membuka lembaran baru, atau telah terlalu lelah bertemu pria random serta kebahagiaan yang semu.
                                </p>
                                <p>
                                    Di buku ini, Saya akan membahas secara lengkap, luar dan dalam bagaimana caranya agar Kamu <b>bisa bahagia dengan kehidupan romansa, mulai dari kondisi single hingga ketika bertemu pria yang tepat.</b>
                                </p>
                                <p>
                                    Dan <b>semua dimulai dari DIRI KAMU.</b>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Col className="__rv-free-headline-text-sec-four-po-member">
                                <h2>Lihat, dengar dan rasakan testimoni jujur
                                    dari pembaca Happy Love Guide sebelumnya..
                                </h2>
                            </Col>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-3">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/1.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/4.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/5.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/6.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/7.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/8.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={6} className="d-flex justify-content-center">
                            <div className="thumbnail-member-po">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/9.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={6} className="d-flex justify-content-center">
                            <div className="thumbnail-member-po ml-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/10.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            {/* <Container className="padding-bottom-po-member">
                <Row className="wrape-get-ebook justify-content-center">
                    <h2 className="font-get-ebook">
                        KENAPA KAMU HARUS PRE ORDER SEKARANG?
                    </h2>
                    <div className="__rv-free-wrappe-text-desc-po-member text-center">
                        <p className="text-white">
                            Buku Happy Love Guide dicetak terbatas dalam periode waktu tertentu. Harga diskon yang <br /> ditawarkan pun hanya berlaku 3 HARI SAJA!
                        </p>
                        <p className="text-white">
                            Jangan sampai Kamu lewatkan kesempatan ini karena Buku Happy Love Guide dicetak Hard Cover <br /> secara Exclusive dengan bonus yang belum tentu akan sama!
                        </p>
                        <p className="text-white">
                            Jika Kamu tidak pre-order sekarang, maka Kamu akan melewatkan kesempatan untuk <br />mendapatkan:
                        </p>
                    </div>
                </Row>
                <Container className="bg-cover-product-po-member">
                    <Row className="justify-content-center w-bonus-po-hlg">
                        <Col lg={4}>
                            <div className="thumbnail-img-member-bonus-po">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/Mockup-hlg-f.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="p-text-desc-member-po-hlg">
                                <Row>
                                    <Col lg="2">
                                        <span className="border-green-circle-bonus-po">
                                            <LazyLoadImage
                                                alt={'Woman HLG'}
                                                src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/1-circle.png'}
                                                className={'img img-fluid'}
                                                effect="blur"
                                            />
                                        </span>
                                    </Col>
                                    <Col lg="10">
                                        <p>
                                            <b>Dicetak secara FULL COLOR</b> dengan Hard Cover yang membuat buku ini semakin eksklusif dan photogenic untuk kamu foto!
                                        </p>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center w-bonus-po-hlg mt-5">
                        <Col lg={4} xs={{ order: 'first' }} lg={{ order: 'last' }}>
                            <div className="thumbnail-img-member-bonus-po">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/bonus-1.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="p-text-desc-member-po-hlg">
                                <Row>
                                    <Col lg="2">
                                        <span className="border-green-circle-bonus-po">
                                            <LazyLoadImage
                                                alt={'Woman HLG'}
                                                src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/no-2.png'}
                                                className={'img img-fluid'}
                                                effect="blur"
                                            />
                                        </span>
                                    </Col>
                                    <Col lg="10">
                                        <p>
                                            <b>Bonus Sticker Lucu</b> yang bisa kamu tempel di buku jurnal kamu (Khusus 500 Orang Pembeli Pertama)

                                        </p>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center w-bonus-po-hlg mt-5">
                        <Col lg={4}>
                            <div className="thumbnail-img-member-bonus-po">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/bonus-2.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="p-text-desc-member-po-hlg">
                                <Row>
                                    <Col lg="2">
                                        <span className="border-green-circle-bonus-po">
                                            <LazyLoadImage
                                                alt={'Woman HLG'}
                                                src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/no-3.png'}
                                                className={'img img-fluid'}
                                                effect="blur"
                                            />
                                        </span>
                                    </Col>
                                    <Col lg="10">
                                        <p>
                                            Dalam 30 menit kamu langsung bisa tahu <b>Cara Bikin Kriteria Pria Tepat
                                            </b> yang sesuai untuk kamu TANPA terbebani standar orang lain
                                        </p>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center w-bonus-po-hlg mt-5">
                        <Col lg={4} xs={{ order: 'first' }} lg={{ order: 'last' }}>
                            <div className="thumbnail-img-member-bonus-po">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/bonus-3.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="p-text-desc-member-po-hlg">
                                <Row>
                                    <Col lg="2">
                                        <span className="border-green-circle-bonus-po">
                                            <LazyLoadImage
                                                alt={'Woman HLG'}
                                                src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/no-4.png'}
                                                className={'img img-fluid'}
                                                effect="blur"
                                            />
                                        </span>
                                    </Col>
                                    <Col lg="10">
                                        <p>
                                            <b>Bonus Buku fisik Buat Dia Terus Kembali.</b>
                                            <br />Pelajari Buat Dia Terus Kembali <a href="https://buatdiakembali.com/" target="_BLANK" className="text-danger" rel="noreferrer"> disini! </a>
                                        </p>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center w-bonus-po-hlg mt-5">
                        <Col lg={4}>
                            <div className="thumbnail-img-member-bonus-po">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/bonus-4.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="p-text-desc-member-po-hlg">
                                <Row>
                                    <Col lg="2">
                                        <span className="border-green-circle-bonus-po">
                                            <LazyLoadImage
                                                alt={'Woman HLG'}
                                                src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/no-5.png'}
                                                className={'img img-fluid'}
                                                effect="blur"
                                            />
                                        </span>
                                    </Col>
                                    <Col lg="10">
                                        <p>
                                            <b>FREE Curhat</b> 1 pertanyaan yang akan dibalas melalui <b>Audio Rekaman</b> langsung dari Jose Aditya. Khusus 200 Orang Pembeli Pertama)
                                        </p>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center w-bonus-po-hlg mt-5">
                        <Col lg={4} xs={{ order: 'first' }} lg={{ order: 'last' }}>
                            <div className="thumbnail-img-member-bonus-po-member">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/mockup-jose.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="p-text-desc-member-po-hlg">
                                <Row>
                                    <Col lg="2">
                                        <span className="border-green-circle-bonus-po">
                                            <LazyLoadImage
                                                alt={'Woman HLG'}
                                                src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/no-6.png'}
                                                className={'img img-fluid'}
                                                effect="blur"
                                            />
                                        </span>
                                    </Col>
                                    <Col lg="10">
                                        <p>
                                            50 Checklist yang memudahkan kamu untuk mengetahui apakah dia pria yang tepat TANPA hubungan yang melelahkan dan buang-buang waktu!
                                            <br /> <br />
                                            (Jika buku terjual mencapai 500 eksemplar)

                                        </p>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center w-bonus-po-hlg mt-5">
                        <Col lg={4}>
                            <div className="thumbnail-img-member-bonus-po">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/30-Hari-Mentoring.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="p-text-desc-member-po-hlg">
                                <Row>
                                    <Col lg="2">
                                        <span className="border-green-circle-bonus-po">
                                            <LazyLoadImage
                                                alt={'Woman HLG'}
                                                src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/no-7.png'}
                                                className={'img img-fluid'}
                                                effect="blur"
                                            />
                                        </span>
                                    </Col>
                                    <Col lg="10">
                                        <p>
                                            Mentoring 1 jam dalam 30 hari setiap Sabtu dengan Jose Aditya via Youtube Live dengan total Mentoring 4 Kali.
                                        </p>
                                        <p>
                                            Untuk tahu progress kamu sesuai dengan teknik yang ada di buku Happy Love Guide. Seru banget kan?
                                            <br /><br />
                                            Ajak semua teman kamu untuk ikut PO Buku Happy Love Guide agar bisa unlocked bonusnya! :D
                                            <br /><br />
                                            (Jika buku terjual mencapai 1000 eksemplar)
                                        </p>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container> */}
            <Container fluid className="bg-po-member-white">
                <Container>
                    {/* <Row role="button" onClick={handleShow}>
                        <Col lg={12}>
                            <div className="d-flex justify-content-center view-mobile-po-member">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/new-range.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                    </Row> */}
                    {/* <Row>
                        <Col lg={12}>
                            <div className="bg-bonus-range">
                                <p>
                                    KUOTA BONUS PRE-ORDER
                                    BUKU HAPPY LOVE GUIDE
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-po-hlg-range">
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">

                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="p-text-range-bonus">
                                <p>
                                    200 Pembeli Pertama
                                </p>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="p-text-range-bonus">
                                <p>
                                    201 - 500 Pembeli Pertama
                                </p>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="p-text-range-bonus">
                                <p>
                                    501 - 1000 Pembeli Pertama
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-po-hlg-range">
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="p-text-range-bonus">
                                <p>
                                    <b>Hard Cover</b>
                                </p>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-po-hlg-range">
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="p-text-range-bonus">
                                <p>
                                    <b>Bonus Buku Buat Dia Terus Kembali</b>
                                </p>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-po-hlg-range">
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="p-text-range-bonus">
                                <p>
                                    <b>Bonus Sticker Lucu</b>
                                </p>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                -
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-po-hlg-range">
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="p-text-range-bonus">
                                <p>
                                    <b>
                                        Free Video “ Avatar Secret”
                                    </b>
                                </p>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-po-hlg-range">
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="p-text-range-bonus">
                                <p>
                                    <b>
                                        Free Audio Curhat
                                    </b>
                                </p>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                <CheckCircle className="iconFree-po-range-hlg" />
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                -
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={3} className="border-po-hlg-range">
                            <div className="icon-range-bonus">
                                -
                            </div>
                        </Col>

                    </Row> */}
                    {/* <Row>
                        <Col lg={12}>
                            <h1 className="font-happy-po-member padding-text-po mt-5">
                                Total Benefit Senilai : <br /> Rp. 2.847.000,-
                            </h1>
                        </Col>
                    </Row> */}
                </Container>

            </Container>
            <Container fluid className="bg-sectwo bg-white"></Container>
            <Container fluid className="bg-faq-po">
                <Container className="text-fast-need-po">
                    <Row>
                        <Col lg={12}>
                            <h3 className="how-finish-order-po-member">
                                Untuk Menyelesaikan Order :
                            </h3>
                            <p className="text-desc-order-po-member">
                                Yang perlu saya lakukan hanyalah <b>KLIK TOMBOL HIJAU </b> sesuai dengan wilayah terdekat dan langsung ke link chat dengan CS kami lalu kirim format order yang sudah tersedia.

                            </p>
                            <h3 className="price-order-po-member-normal">
                                Harga normal : <span className="text-danger"><del>Rp 558.000</del></span>
                            </h3>
                            <p className="text-price-po-member">
                                <b>Kini Hanya</b>
                            </p>
                        </Col>
                    </Row>
                    {/* <Row className="justify-content-center"> */}
                    {/* <Col lg={6} className="mb-5">
                            <Card className="text-center">
                                <Card.Header>
                                    <div className="only-member-po-hlg">
                                        <p>Periode I 23 - 26 Juni 2021</p>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>
                                        <div className="only-member-po-hlg">
                                            <p>Khusus Member Lovecoach.id</p>
                                        </div>
                                    </Card.Title>
                                    <Card.Text>
                                        <div className="only-member-po-hlg">
                                            <h2>Rp. 279.000</h2>
                                            <p>Hemat Rp 279.000,- (50% lebih murah)</p>
                                        </div>
                                        <hr />
                                        <div className="nononly-member-po-hlg">
                                            <p>Non - Member</p>
                                            <h2>Rp. 329.000,-</h2>
                                            <p>Hemat Rp 229.000,- (41% lebih murah)</p>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <div className="only-member-po-hlg">
                                        <p>Periode I 23 - 26 Juni 2021</p>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </Col> */}
                    {/* <Col lg={6}>
                            <Card className="text-center">
                                <Card.Header>
                                    <div className="only-member-po-hlg">
                                        <p>
                                            Periode II 28 Juni - 1 Juli 2021
                                        </p>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>
                                        <div className="only-member-po-hlg">
                                            <p>Khusus Member Lovecoach.id</p>
                                        </div>
                                    </Card.Title>
                                    <Card.Text>
                                        <div className="only-member-po-hlg">
                                            <h2>Rp. 329.000</h2>
                                            <p>Hemat Rp 229.000,- (41% lebih murah)</p>
                                        </div>
                                        <hr />
                                        <div className="nononly-member-po-hlg">
                                            <p>Non - Member</p>
                                            <h2>Rp. 379.000</h2>
                                            <p>Hemat Rp 179.000,- (32% lebih murah)</p>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <div className="only-member-po-hlg">
                                        <p>Periode II 28 Juni - 1 Juli 2021 </p>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </Col> */}
                    {/* </Row> */}
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="nononly-member-po-hlg">
                                {/* <p>Non - Member</p> */}
                                <h2 className="text-white">Rp. 379.000</h2>
                                <p className="text-white">Hemat Rp 179.000,- (32% lebih murah)</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center margin-top-price-order-member">
                        <Col lg={12}>
                            <p className="font-guide-single-po">
                                Pesan Dibawah Ini
                            </p>
                        </Col>
                        <Col lg={12}>
                            <div className="button-center-po-member">
                                <a href="https://wa.link/lvh4ns" target="_BLANK" rel="noreferrer">
                                    <Button className="button-style-sl-po-green ml-1" size="md" >
                                        Jabodetabek
                                    </Button>
                                </a>
                                <a href="https://wa.link/3j7m8u" target="_BLANK" rel="noreferrer">
                                    <Button className="button-style-sl-po ml-1" size="md">
                                        Non Jabodetabek
                                    </Button>
                                </a>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
            <Container fluid className="mb-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="__rv-free-text-headline-sec-six-po-member" id="learn">
                                <h2><i>Semakin cepat kamu miliki Happy Love Guide, semakin cepat kamu bisa:</i></h2>
                            </div>
                            <div className="__rv-free-box-white-rounded">
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc"><b>Terhindar dari hubungan "jalanin aja dulu.."</b> tapi gak jelas arah dan tujuannya </p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Kuasai teknik <b>"Wanita yang Memilih, Pria yang Mengejar"</b></p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Punya <b>mindset yang tepat</b> untuk memulai, menjalani dan menciptakan hubungan yang sehat dan bahagia</p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Tau skill, sikap & mindset yang dibutuhkan untuk <b>mendapat pria yang tepat</b></p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc"><b>Tau hal seksi yang bisa memikat pria namun tidak murahan</b></p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Punya <b>skill validasi</b> agar memudahkan memilih diantara beberapa yang mendekat dan tidak berlarut-larut dalam proses yang menguras emosi</p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc">Kuasai cara paling efektif bertemu pria yang tepat <b>meskipun kamu gak punya banyak kenalan atau jarang ada waktu bersosialisasi</b>
                                        </p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc"><b>Punya panduan yang jelas,</b> langkah demi langkah dari single hingga bertemu pria yang tepat
                                        </p>
                                    </Col>
                                </div>
                                <div className="row justify-content-start">
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                        <CheckCircle className="iconFree" />
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs>
                                        <p className="__rv-free-font-desc"><b>Bebas dari hubungan drama,</b> bucin dan semua hubungan romansa yang gak pake logika
                                        </p>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5">
                        <Col lg={12}>
                            <p className="font-guide-single-po">
                                Pesan Dibawah Ini
                            </p>
                        </Col>
                        <Col lg={12}>
                            <div className="button-center-po-member">
                                <a href="https://wa.link/lvh4ns" target="_BLANK" rel="noreferrer">
                                    <Button className="button-style-sl-po-green ml-1" size="md" >
                                        Jabodetabek
                                    </Button>
                                </a>

                                <a href="https://wa.link/3j7m8u" target="_BLANK" rel="noreferrer">
                                    <Button className="button-style-sl-po ml-1" size="md">
                                        Non Jabodetabek
                                    </Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg-white padding-bottom-po-member-hlg">
                <Container>

                    <Row>
                        <Col lg={12}>
                            <Col className="__rv-free-headline-text-sec-four-po-member">
                                <h2>Lihat, dengar dan rasakan testimoni jujur
                                    dari pembaca Happy Love Guide sebelumnya..
                                </h2>
                            </Col>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-3">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/10Urutan1.jpg'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/10Urutanke2.jpg'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/11.jpg'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/12.jpg'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/13.jpg'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/14.jpg'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/15.jpg'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/16.jpg'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-member-po mb-5">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/pre-order-assets/17.jpg'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg-white padding-bottom-po-member-hlg">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-headline-about-creator-po-member">
                                About Creator
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="thumbnail-about-creator">
                                <LazyLoadImage
                                    alt={'Woman HLG'}
                                    src={'https://bahasapria.com/wp-content/uploads/2017/11/foto.png'}
                                    className={'img img-fluid'}
                                    effect="blur"
                                />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="desc-text-about-creator">
                                <p>Jose Aditya, <b>Love Life Coach</b></p>
                                <p className="mb-5">Sebelum memulai debutnya sebagai pelatih percintaan Wanita, Jose Aditya adalah seorang marketer (8 tahun) hypnothrerapist dan resource therapyst (3 tahun), Dengan niat awal untuk membantu sang kakak perempuan untuk menggapai cinta, Jose Aditya melakukan riset mendalam mengenai kehidupan cinta wanita dari aspek psikologis, biologis dan neurosciene. Setelah sedikit banyak berhasil, dia sadar bahwa bukan hanya kakaknya seorang saja yang membutuhkan solusi ini, melaikan juga jutaan wanita-wanita untuk bahagia dalam kehidupan cintanya, dan kini telah berhasil menyentuh kehidupan cinta puluhan ribu wanita indonesia perjalanan masih jauh kawan!</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg-faq">
                <Container>
                    <Row>
                        <Col lg={12} xs={12} className="faq">
                            <h2>F A Q</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} xs={12}>
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        Apa saja yang dibahas dalam Happy Love Guide?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            Lihat apa saja yang bisa Kamu pelajari,
                                            <AnchorLink href='#learn'>disini</AnchorLink>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="mt-3">
                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                        Bagaimana cara belinya?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            Lihat cara beli,  <AnchorLink href='#howbuy'>disini</AnchorLink>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="mt-3">
                                    <Accordion.Toggle as={Card.Header} eventKey="2">
                                        Kapan Pre-Order berakhir?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            Pre-Order akan berakhir tanggal 30 September 2021. Jadi jangan sampai ketinggalan ya!
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="mt-3">
                                    <Accordion.Toggle as={Card.Header} eventKey="3">
                                        Berapa lama pengiriman buku Happy Love Guide?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            Pengiriman buku Happy Love Guide dimulai tanggal 20 Agustus 2021. Kisaran waktu pengiriman H+3 hari atau tergantung padatnya ekspedisi.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="mt-3">
                                    <Accordion.Toggle as={Card.Header} eventKey="4">
                                        Wah jadi gak sabar mau punya buku Happy Love Guide sekarang!
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                            Yes, saran kami.. Segera putuskan beli sebelum waktu habis dan kamu menyesal ketinggalan bonus dan diskonnya.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="mt-3">
                                    <Accordion.Toggle as={Card.Header} eventKey="8">
                                        Masih ada pertanyaan?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="8">
                                        <Card.Body>
                                            Email kami di cs@lovecoach.id atau via WhatsApp: 0812-8114-5328 (Gilang), WhatsApp: 0812-1913-5268 (Fakhri)
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>

                    </Row>
                    <Row>
                        <Col className="footer-col">
                            <footer>
                                <span>
                                    © {new Date().getFullYear()}, Built with
                                    {` `}
                                    PT Jose Upmind Digital Optima
                                </span>
                            </footer>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* <Modal
                show={show} onHide={handleClose}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal> */}

            {/* end section one */}
        </>
    )
}